import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://api.father.srv.br', // URL base para testes locais
  headers: {
    'Content-Type': 'application/json',
  },
});

const apiService = {
  getDiagnostics() {
    return apiClient.get('/api/v1/diagnostics');
  },
  getCompanies() {
    return apiClient.get('/api/v2/companies');
  },
  getContacts() {
    return apiClient.get('/api/v2/contacts');
  },
  getStatusMatches() {
    return apiClient.get('/api/v2/statusmatches');
  },
  postCompanies(data) {
    return apiClient.post('/api/v2/companies', data);
  },
  postContacts(data) {
    return apiClient.post('/api/v2/contacts', data);
  },
  postStatusMatches(data) {
    return apiClient.post('/api/v2/status-mach', data);
  },
  getRounds() {
    return apiClient.get('/api/v2/rounds'); // Adicionado o método getRounds
  },
  postRounds(data) {
    return apiClient.post('/api/v2/rounds', data);
  },
  // Adicione outros métodos conforme necessário
};

export default apiService;
